import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Typography, Box} from 'components/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import {DragAndDropArea} from 'components/ui/forms/file-upload/DragAndDropArea.jsx';

const FileNameAndStatusWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 8px 0;
    width: 100%;

    .file-status {
        color: ${({theme}) => theme.palette.secondary.dark};
        white-space: nowrap;
        font-style: italic;
    }

    .file-name {
        color: ${({theme}) => theme.palette.secondary.dark};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: ${({theme}) => theme.typography.fontWeightSemibold};
        margin-right: 5px;
    }

    .MuiSvgIcon-root {
        color: ${({theme}) => theme.palette.secondary.main};
    }
`;

const UploadedFileDisplay = ({fileName, onRemove, border = 'none'}) => {
    return (
        <DragAndDropArea border={border}>
            <FileNameAndStatusWrapper className="border-area">
                <Typography className="file-status">Uploaded File</Typography>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Typography className="file-name">{fileName}</Typography>
                    <DeleteIcon
                        onClick={onRemove}
                        cursor="pointer"
                        fontSize="small"
                        color="secondary"
                    />
                </Box>
            </FileNameAndStatusWrapper>
        </DragAndDropArea>
    );
};

UploadedFileDisplay.propTypes = {
    fileName: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    border: PropTypes.string,
};

export {UploadedFileDisplay};
